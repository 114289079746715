import React from 'react';
import { Router } from '@reach/router';

import AuthCallback from '../../components/AuthCallback';

const Callback = () => (
  <Router>
    <AuthCallback path="/callback" />
  </Router>
);

export default Callback;
