const CART_KEY = 'dolonline.cart';
const PARSED_PRODUCTS_KEY = 'dolonline.parsed_products';
const SELECTED_PAYMENT_METHOD_KEY = 'dolonline.selected_payment_method';

export function saveCart(cart) {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
}

export function getCart() {
  return JSON.parse(localStorage.getItem(CART_KEY));
}

export function clear() {
  localStorage.removeItem(CART_KEY);
  localStorage.removeItem(PARSED_PRODUCTS_KEY);
}

export function saveParsedProducts(cart) {
  localStorage.setItem(PARSED_PRODUCTS_KEY, JSON.stringify(cart));
}

export function getParsedProducts() {
  return JSON.parse(localStorage.getItem(PARSED_PRODUCTS_KEY));
}

export function saveSelectedPaymentMethod(selectedPaymentMethod) {
  localStorage.setItem(SELECTED_PAYMENT_METHOD_KEY, JSON.stringify(selectedPaymentMethod));
}

export function getSelectedPaymentMethod() {
  return JSON.parse(localStorage.getItem(SELECTED_PAYMENT_METHOD_KEY));
}
