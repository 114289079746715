import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Loading from 'react-loading';

import * as Auth from '../utils/auth';
import * as Cart from '../utils/cart';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function AuthCallback({ location }) {
  useEffect(() => {
    Auth.isLogged().then(isLogged => {
      if (isLogged) {
        if (Cart.getCart()) {
          navigate('/pricing');
        } else {
          const redirectTo = sessionStorage.getItem('redirectTo');
          if (redirectTo) {
            sessionStorage.removeItem('redirectTo');
            window.location.href = redirectTo;
          } else {
            if (Auth.isStudent()) {
              window.location.href = '/app/lms/my-courses';
              return;
            }
            navigate('/app');
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    const getAuth = async () => {
      await Auth.getAuthFromHash();
      const auth = Auth.getAuth();
      const userInfo = await Auth.getUserInfo();
      Auth.saveAuth({ ...auth, userInfo });
      if (Cart.getCart()) {
        if (Auth.isBrowser() && window.innerWidth > 768) {
          navigate('/pricing/', { state: { afterLogin: true } });
        } else {
          navigate('/pricing/term-and-policy/', { state: { afterLogin: true } });
        }
      } else {
        const redirectTo = sessionStorage.getItem('redirectTo');
        if (redirectTo) {
          sessionStorage.removeItem('redirectTo');
          window.location.href = redirectTo;
        } else {
          if (Auth.isStudent()) {
            window.location.href = '/app/lms/my-courses';
            return;
          }
          navigate('/app');
        }
      }
    };

    getAuth().catch(error => {
      // eslint-disable-next-line no-console
      console.error('Login fail', error);
      navigate('/login');
    });
  }, [location.hash]);

  return (
    <Wrapper>
      <Loading type="bars" width={100} color="#4e4e4e" />
    </Wrapper>
  );
}

AuthCallback.propTypes = {
  location: PropTypes.shape({}),
};

AuthCallback.defaultProps = {
  location: undefined,
};

export default AuthCallback;
